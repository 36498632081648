// $primary: #a51616;
$primary: #000; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Jomhuria|Passion+One|Sansita+One');

h1 {
	font-family: 'Sansita One', cursive;
    padding: 40px 0;
    font-size: 50px;
} 

h2, h3 {
	font-family: 'Passion One', cursive;
	font-size: 30px;
} 

p {
	font-family: 'Jomhuria', cursive;
    font-size: 28px;
}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar-default {
	background-color: #fff;
}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 140px;
		display: flex;
		align-items: center;
		font-size: 17px;


		@media (max-width: 767px) {
		    height: 35px;
		    display: inline-block;
		    font-size: 15px;
		}

		&:focus, &:visited {
    		outline: 0;
  		}

	    &:hover {
	    	background: transparent;
	    	color: #d51f40;
	    }
	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 35px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: #d51f40;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: #000;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;

		border-bottom: 1px solid #d51f40;
		text-align: center;
	}
	
	button {
		background: #d51f40;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: #d51f40;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: #d51f40;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: #fff;
	color: $blk;

	p {
		font-size: 22px;
	}

	a {
		color: $blk;

		&:hover {
			color: lighten($blk, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.shadow {
  background: transparent;
  text-shadow: 0 1px 0 rgba(0,0,0, 0.99);
}

.shadow2 {
  background: transparent;
  text-shadow: 0 1px 0 rgba(213, 31, 64, 0.99);
}

.btn {
  background-color:rgb(213, 31, 64);
  border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 15px 30px;
  color: $wht;

  &:focus:active {
  	outline: 0;
  	-webkit-box-shadow: none;
    box-shadow: none;
  }
	
  &:hover {
  background-color: transparent;
  //border-color: rgba(255,255,255,1); 
  // background: 
  color: $wht;
	}
}

.header {
    // background: url(../img/banner.jpg);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%, #000000 100%), url(../img/banner.jpg) no-repeat;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 50%;
    background-size: cover;
    padding: 250px 0px;
    color: #fff;
    background-attachment: unset;
}

@media (max-width: 568px) {
	.header {
	    padding: 125px 0px;
	    background-position: 34% 50%;
		background-attachment: unset;
	}
}



@media (max-width: 320px) {
	.header {
	    padding: 125px 0px;
	    background-position: 34% 50%;
		background-attachment: unset;
	}
}

@media (max-width: 568px) {
	.header {
		h1 {
			font-size: 35px;	
	}
		h2 {
			font-size: 28px;	
		}
	}
}


.intro-divider {
    width: 400px;
    border-top: 1px solid #f8f8f8;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.content-section-a {
    // background: url(../img/banner2.jpg);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.7) 100%, #000000 100%), url(../img/banner2.jpg) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.7) 100%, #000000 100%), url(../img/banner2.jpg) no-repeat;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 50%;
    background-size: cover;
    padding: 100px 0px;
    color: #fff;
    background-attachment: unset;
}

@media (min-width: 320px) {
	.content-section-a {
		background-position: 30% 0%;
		background-attachment: unset;
	}
}

.content-section-b {
    // background: url(../img/banner3.jpg);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.7) 100%, #000000 100%), url(../img/banner3.jpg) no-repeat;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.7) 100%, #000000 100%), url(../img/banner3.jpg) no-repeat;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    padding: 150px 0px;
    color: #fff;
    background-attachment: unset;
}

.content-section-c {
    padding: 50px 0px;
    color: #blk;
}

.footer {
    background: url(../img/banner4.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 0% 30%;
    background-size: cover;
    padding: 200px 0px;
    color: #fff;
    background-attachment: unset;
}

	@media (max-width:320px) {
		.footer{
		background-position: 30% 0%;
		background-attachment: unset;
	}
}

.logo {
	max-width: 175px;
	width: 100%;
}

@media (max-width: 767px) {

.logo {
	max-width: 125px;
	width: 100%;
	}
}